// import useToken from 'src/checkauth.js'
import React, { Component } from 'react'
import { HashRouter, Route, Switch } from 'react-router-dom'
import './scss/style.scss'


const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
const Register = React.lazy(() => import('./views/pages/register/Register'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const ExpiredToken = React.lazy(() => import('./views/pages/token/ExpiredToken'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))
const Reset = React.lazy(() => import('./views/pages/reset/Reset'))
const PwdReset = React.lazy(() => import('./views/pages/forgot-password/Reset'))

const tokenString = sessionStorage.getItem('isonga');
// const userToken = JSON.parse(tokenString);


class App extends Component {
  render() {
    if(tokenString == null){
      return (
        <HashRouter>
          <React.Suspense fallback={loading}>
            <Switch>
              <Route exact path="/login" name="Login Page" render={(props) => <Login {...props} />} />
              <Route path="/reset/:resetToken" name="Password Reset Page" render={(props) => <Reset resetToken={props.match.params.resetToken} {...props} />} />
              <Route
                exact
                path="/register"
                name="Register Page"
                render={(props) => <Register {...props} />}
              />
              <Route exact path="/404" name="Page 404" render={(props) => <Page404 {...props} />} />
              <Route exact path="/expired" name="Token Expired" render={(props) => <ExpiredToken {...props} />} />
              <Route exact path="/forgot-password" name="Reset Password" render={(props) => <PwdReset {...props} />} />
              <Route exact path="/500" name="Page 500" render={(props) => <Page500 {...props} />} />
              <Route exact path="*" name="Login Page" render={(props) => <Login {...props} />} />
            </Switch>
          </React.Suspense>
        </HashRouter>
      )
    }
    else{
      return (
        <HashRouter>
          <React.Suspense fallback={loading}>
            <Switch>
              <Route exact path="/login" name="Login Page" render={(props) => <Login {...props} />} />
              <Route path="/reset/:resetToken" name="Password Reset Page" render={(props) => <Reset resetToken={props.match.params.resetToken} {...props} />} />
              <Route exact path="/phishing/Profile/:id" name="Profile Phishing" render={(props) => <DefaultLayout {...props} />} />
              <Route
                exact
                path="/register"
                name="Register Page"
                render={(props) => <Register {...props} />}
              />
              <Route exact path="/404" name="Page 404" render={(props) => <Page404 {...props} />} />
              <Route exact path="/expired" name="Token Expired" render={(props) => <ExpiredToken {...props} />} />
              <Route exact path="/forgot-password" name="Reset Password" render={(props) => <PwdReset {...props} />} />
              <Route exact path="/500" name="Page 500" render={(props) => <Page500 {...props} />} />
              <Route path="/" name="Home" render={(props) => <DefaultLayout {...props} />} />
            </Switch>
          </React.Suspense>
        </HashRouter>
      )
    }
  }
}

export default App
